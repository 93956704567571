import React from "react";
import banner from "../../assets/banner.png";
import Bounce from "react-reveal/Bounce";
import Shake from "react-reveal/Shake";
import Foooter from "../../components/Footer/Foooter";
import "./Home.css";

function Home() {
  return (
    <div>
      <div className="home">
        <div className="main">
          <div className="main_left">
            <Bounce left>
              <img className="banner_img" src={banner} alt="banner" />
            </Bounce>
          </div>
          <div className="main_right">
            <p className="name">
              <Shake>
                👋🏾
              </Shake>
              Hello I'm Khalid Ibrahim
            </p>
            <p className="headline">
              I’m a Frontend developer and Designer based in the Ethiopia.
            </p>
          </div>
        </div>
      </div>
      <Foooter style="footer" />
    </div>
  );
}

export default Home;
