import React from "react";
import "./App.css";
import Header from "./components/Header/Header";
import Home from "./Pages/HomePage/Home";
import Contact from "./Pages/ContactPage/Contact";
import { Routes, Route } from "react-router-dom";
import Projects from "./Pages/ProjectsPage/Projects";
import Loglib from "@loglib/tracker/react";

function App() {
  console.log("location is " + window.location);
  const loc = window.location;
  return (
    <div className="App">
      <Loglib
        config={{
          id: "khalidtech-8a55c_web",
          host: "https://www.loglib.io",
        }}
      />
      <Header />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/projects" element={<Projects />} />
      </Routes>
    </div>
  );
}

export default App;
