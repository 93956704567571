import React from 'react'
import Zoom from 'react-reveal/Zoom';
import Bounce from 'react-reveal/Bounce';

function Projects_card( {img_name ,title, link ,description, ghlink} ) {
    return (
        <div>
                 <div className="projects_wrapper">
                 <div className="project">
             <Zoom>
                    <img src={img_name} className='project-img' alt="" />
                    </Zoom>
                    <div className="text">
                    <h3>{title}</h3>
                    <br />
                    <p>{description}</p>
                    Live link:
                    <a href={link} className='link' target="blank">
                        {link}
                    </a>
                    <br />
                    Github link:
                    <a href={ghlink} className='link' target="blank">
                        {ghlink}
                    </a>
                    </div>
                    </div>
                    </div>
                    
        </div>
    )
}

export default Projects_card
