import React from 'react'
import Bounce from 'react-reveal/Bounce';

function Projects_card( {ghlink,img_name ,title, link ,description, ...props} ) {
    return (
        <div>
             <div className="projects_wrapper">
                 <div className="project">
                    <div className="text">
                    <h3>{title}</h3>
                    <br />
                    <p>{description}</p>
                    live link:
                    <a href={link} className='link' target="blank">
                        {link}
                    </a>
                    <br />
                    github link:
                    <a href={ghlink} className='link' target="blank">
                        {ghlink}
                    </a>
                    </div>
                    <Bounce><img src={img_name} className='project-img' alt="" /></Bounce>
                    </div>
                    </div>
                    
        </div>
    )
}

export default Projects_card
