import React, { useState } from 'react'
import { Link } from "react-router-dom";
import './Header.css'

function Header() {
  const [showLinks, setShowLinks] = useState(false);
  const handleToggle = () => {
    setShowLinks(!showLinks);
  }
    return (
        <div>
            <div className="header">
       <div className="header_left">
        <Link to="/">
     <div className="logo">Khalid Ibrahim</div>
        </Link>
       </div>
       <div className="header_right">
       <Link to="/">
     <div className="nav">Home</div>
       </Link>
       <Link to="/projects">
     <div className="nav">Projects</div>
       </Link>
       <Link to="/contact">
         
     <div className="nav">Contact</div>
       </Link>
       </div>
       </div>

       <div className="nav_mobile">
              <div className="header_left_mobile">
              <Link to="/">
     <div className="logo">Khalid Ibrahim</div>
        </Link>
       </div>
        {/* <button role="button" aria-label="Display the menu" class="menu" onClick={handleToggle}>nav</button> */}
       <input type="checkbox" role="button" aria-label="Display the menu" class="menu" onClick={handleToggle}></input>
                </div>
       {showLinks &&
       <div className="header_right_mobile">
       <Link to="/">
     <div className="nav">Home</div>
       </Link>
       <Link to="/projects">
     <div className="nav">Projects</div>
       </Link>
       <Link to="/contact">
         
     <div className="nav">Contact</div>
       </Link>
       {/* <input type="checkbox" role="button" aria-label="Display the menu" class="menu"></input> */}
       </div>
}
        </div>
    )
}

export default Header
