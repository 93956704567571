import { createClient } from '@supabase/supabase-js'
const supabaseUrl = 'https://uwmllepkabyaiouqeyxx.supabase.co'
const supabaseKey = 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6InV3bWxsZXBrYWJ5YWlvdXFleXh4Iiwicm9sZSI6ImFub24iLCJpYXQiOjE2ODMyMjY3MzYsImV4cCI6MTk5ODgwMjczNn0.UhxzLGYyk7uTwAxKlzFYvR_1iiHr3xiPAVDM9DA2hEg'
const supabase = createClient(supabaseUrl, supabaseKey)

function errorGuard(error) {
  if (error) {
    console.log(error);
  }
  return [];
}

export async function getprojects() {
  const { data, error } = await supabase.from('projects')
  .select('id')
  return errorGuard(error);
}

export const fetchProjects = async () => {
  let { data: projects, error } = await supabase.from("projects").select("*").order("order", { ascending: true });
  return projects;
};