import React from "react";
import xyz from "../../assets/xyz.png";
import covidet from "../../assets/covidet.png";
import ashewa from "../../assets/ashewa.png";
import rps from "../../assets/rps.png";
import abc from "../../assets/abc.png";
import Projects_card from "../../components/Cards/Projects_card";
import Projects_card_reverse from "../../components/Cards/Projects_card_reverse";
import Foooter from "../../components/Footer/Foooter";
import "./Projects.css";
import { fetchProjects, getprojects } from "../../supabase";
import { SupabaseClient } from "@supabase/supabase-js";
import { createClient } from '@supabase/supabase-js'

function Projects() {
    const [projects, setProjects] = React.useState([]);
    React.useEffect(() => {
        const getProjects = async () => {
            const projectsFromServer = await fetchProjects();
            setProjects(projectsFromServer);
        };
        getProjects();
    }, []);
    console.log(projects)
  return (
    <>
      <div>
        <div className="desktop">
            {projects.map((p) => (
                <Projects_card
                    img_name={p.p_image}
                    link={p.p_link ? p.p_link : "not available"}
                    title={p.p_title}
                    description={p.p_description}
                    ghlink={p.p_github? p.p_github : "not available"}
                />
            ))}

        </div>
        <div className="mobile">
          {projects.map((p) => (
              <Projects_card_reverse
                  img_name={p.p_image}
                    link={p.p_link ? p.p_link : "not available"}
                    title={p.p_title}
                    description={p.p_description}
                    ghlink={p.p_github? p.p_github : "not available"}
                />
            ))}
        </div>
      </div>
      <Foooter style="footer_proj" />
    </>
  );
}

export default Projects;
