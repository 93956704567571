import React from 'react'
import GitHubIcon from '@mui/icons-material/GitHub';
import Instagram from '@mui/icons-material/Instagram';
import Twitter from '@mui/icons-material/Twitter';
import '../Footer/Footer.css'

function Foooter({style}) {
    return (
        <div className={style}>
            <div className="socials">            
            <a href="https://github.com/khalid-212" target="blank">
                     
                <GitHubIcon/>
                </a>
                <a href="https://www.instagram.com/kalid_js/" target="blank">
                    
                    <Instagram/>
                </a>
                    <a href="https://twitter.com/kalid_js" target="blank">

                <Twitter/>
                    </a>
               
            <div className="footer_text">Designed and Developed by Khalid Ibrahim</div>
            </div>
        </div>
    )
}

export default Foooter
