import React, { useRef } from "react";
import "../ContactPage/Contact.css";
import formbg from "../../assets/contact.png";
import Bounce from "react-reveal/Bounce";
import Foooter from "../../components/Footer/Foooter";
import emailjs from "@emailjs/browser";

function Contact() {
  const form = useRef();
  const sendEmail = (e) => {
    e.preventDefault();
    emailjs
      .sendForm(
        "service_kjrckas",
        "template_rkyp2xb",
        form.current,
        "user_6ag6TmIXff1ueCgvZnK8E"
      )
      .then(
        (result) => {
          console.log(result.text);
          console.log(form.current);
        },
        (error) => {
          console.log(error.text);
        }
      );
  };

  return (
    <>
      <div className="contact_main">
        <div className="wrapper">
          <img className="form_bg" src={formbg} alt="banner" />

          <div className="form">
            <Bounce right>
              <div className="lets_talk">Let's Talk</div>
              <form className="form_wrapper" ref={form} onSubmit={sendEmail}>
                <input
                  className="input sender"
                  type="text"
                  name="user_name"
                  placeholder="Name"
                  required
                />
                <input
                  className="input email"
                  type="email"
                  name="user_email"
                  placeholder="Email"
                  required
                />
                <textarea
                  className="input message"
                  placeholder="Message"
                  name="message"
                  required
                />
                <input className="form_btn" type="submit" value="Send" />
              </form>
            </Bounce>
          </div>
        </div>
      </div>
      <Foooter style="footer" />
    </>
  );
}

export default Contact;
